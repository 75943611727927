function enviar_form(){



    console.log("Envia");



    var filter= /^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/;

    var s_email = $('#email').val();

    var s_name = $('#nombre').val();  

    var s_apellido = $('#telefono').val();  

    var s_msg = $('#mensaje').val();

    var sendMail = "true";



    if (filter.test(s_email)){

        $('#error-email').addClass("hide");      

    } else{

        $('#error-email').removeClass("hide");   

        sendMail = "false";

    }



    if (s_name.length != 0 ){

        $('#error-nombre').addClass("hide");     

    } else{

        $('#error-nombre').removeClass("hide");

        var sendMail = "false";

    } 



    if (s_name.length != 0 ){

        $('#error-apellido').addClass("hide");     

    } else{

        $('#error-apellido').removeClass("hide");

        var sendMail = "false";

    }  



    if (s_msg.length != 0 ){

        $('#error-mensaje').addClass("hide");   

    } else{

        $('#error-mensaje').removeClass("hide");  

        var sendMail = "false";

    }       





    if( sendMail == "true" ) {



        var datos = {

        "nombre" : $('#nombre').val(),

        "telefono" : $('#telefono').val(),

        "email" : $('#email').val(),   

        "mensaje" : $('#mensaje').val(),

        "cenviar" : $('#boton-enviar').val(),

        "_token": getCsrfToken()

    };



    $("#boton-enviar").text("Enviando...");



    $.ajax({

        data:  datos,

        url:   '/enviar-email', 

        type:  'post',

    success:  function (response) {

        $('#mensaje-envio-error').addClass("hide");

        $('#mensaje-envio').removeClass("hide");    

        $('form')[0].reset();

    }

    }).done(function() {

    }).fail(function() {

        $('#mensaje-envio').addClass("hide"); 

        $('#mensaje-envio-error').removeClass("hide");

    }).always(function() {

        $("#boton-enviar").text("Enviar");

    });

        grecaptcha.reset();

        return true;

    } else {

        grecaptcha.reset();

        return false;

}

}



function enviar_catalogo(){



    console.log("enviar_catalogo");



    var filter= /^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/;

    var s_email = $('#email').val();

    var s_name = $('#nombre').val();  

    var s_apellido = $('#telefono').val(); 

    var s_asesoramiento = $('#asesoramiento').val();

    var s_producto = $('#producto').val();

    var s_medida = $('#medida').val(); 

    var s_msg = $('#mensaje').val();

    var s_adjuntos = {};

    var sendMail = "true";

    //var data = new FormData();

    //jQuery.each(jQuery('#a_file')[0].files, function(i, file) {

    //data.append('file-'+i, file);

    //});



    if (filter.test(s_email)){

        $('#error-email').addClass("hide");      

    } else{

        $('#error-email').removeClass("hide");   

        sendMail = "false";

    }



    if (s_name.length != 0 ){

        $('#error-nombre').addClass("hide");     

    } else{

        $('#error-nombre').removeClass("hide");

        var sendMail = "false";

    } 



    if (s_name.length != 0 ){

        $('#error-apellido').addClass("hide");     

    } else{

        $('#error-apellido').removeClass("hide");

        var sendMail = "false";

    }  



    if (s_msg.length != 0 ){

        $('#error-mensaje').addClass("hide");   

    } else{

        $('#error-mensaje').removeClass("hide");  

        var sendMail = "false";

    }       





    if( sendMail == "true" ) {

        

        var datos = {

        "nombre" : $('#nombre').val(),

        "telefono" : $('#telefono').val(),

        "email" : $('#email').val(), 

        "asesoramiento" : $('#asesoramiento').val(), 

        "producto" : $('#producto').val(), 

        "medida" : $('#medida').val(),   

        "mensaje" : $('#mensaje').val(),

        "a_file" : s_adjuntos,

        "cenviar" : $('#boton-enviar').val(),

        "_token": getCsrfToken()



    };



    $("#boton-enviar").text("Enviando...");



    $.ajax({

        data:  new FormData($("#catalogo_form")[0]),

        url:   '/enviar-catalogo', 

        type:  'post',

        processData: false,

        contentType: false,

    success:  function (response) {

        $('#mensaje-envio-error').addClass("hide");

        $('#mensaje-envio').removeClass("hide");    

        $('form')[0].reset();

    }

    }).done(function() {

    }).fail(function() {

        $('#mensaje-envio').addClass("hide"); 

        $('#mensaje-envio-error').removeClass("hide");

    }).always(function() {

        $("#boton-enviar").text("Enviar");

    });

        grecaptcha.reset();

        return true;

    } else {

        grecaptcha.reset();

        return false;

}

}



function getCsrfToken() { 

   var metas = document.getElementsByTagName('meta'); 



   for (var i=0; i<metas.length; i++) { 

      if (metas[i].getAttribute("name") == "csrf-token") { 

         return metas[i].getAttribute("content"); 

      } 

   } 

    return "";

}

            

$(document).ready(function () {

    $('input:file').on('change', function (event) {

        console.log($(event.target.files[0]));

        s_adjuntos = $(event.target.files[0]);

        console.log($("#catalogo_form")[0]);

        console.log(s_adjuntos);

    });

    $('#politica, #comunicacion').on('change', function (event) {

        if (($("#politica").is(":checked")) && ($("#comunicacion").is(":checked"))) {
            $("#boton2").addClass("hidden");
            $("#boton-enviar").removeClass("hidden");
            $("#boton-enviar").addClass("g-recaptcha");
            $("#boton-enviar").addClass("botonenviar");
            $("#aceptado").removeClass("hidden");
            $("#sin-aceptar").addClass("hidden");
        }else {
            $("#boton2").removeClass("hidden");
            $("#boton-enviar").addClass("hidden");
            $("#boton-enviar").removeClass("g-recaptcha");
            $("#boton-enviar").removeClass("botonenviar");
            $("#aceptado").addClass("hidden");
            $("#sin-aceptar").removeClass("hidden");
        }


    });

});


